import React from 'react';

function Contact() {
  const mapImageSrc = 'http://sgmmodernbricks.com/img/map_image.jpg'; // Replace with the actual URL of your map image
  const mapLink = 'https://maps.app.goo.gl/VxeUSfLXZ3vUVXLs6';

  return (
    <div>
    <div className='contact'>
      <p>
        For any type of enquiry, please feel free to contact us at <b>9655546951</b>.
        You can also reach us at <a href="mailto:sgmbricks2021@gmail.com">sgmbricks2021@gmail.com</a>.
      </p>
      
    </div>
    <div className='contact'> <b>Visit Us:</b></div>
   
    <div className='contact'>
    <p>
        
        <a href={mapLink} target="_blank" rel="noopener noreferrer">
          <img
            src={mapImageSrc}
            alt="SGM Modern Bricks Location Map"
            width="600"
            height="450"
            loading="lazy"
          />
        </a>
      </p>
    </div>
      
    </div>
  );
}

export default Contact;
