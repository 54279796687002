import React from 'react'
import "./App.css";

function about() {
  return (
    <div className='about'>
      <p>
         SGM BRICKS began operations on May 1, 2021, with the goal of producing high quality and eco-friendly bricks for quality civil works using high capacity machinery to achieve the required quality to meet criteria.<br />
         SGM Bricks is the first manufacturing plant in Dindigul City to be TUV and ISO 9001:2015 certified for its system driven process and quality control<br />
         
   SGM Bricks provides Solid Bricks  and coal Fly Ash Bricks (White and Brown).
   The standard of excellence in products The strength of its bricks is ensured by its strict adherence to standard methods (from selecting raw materials to assuring compressive strength, under roof curing, water curing, etc.) and quality control, as well as frequent local laboratory examinations.<br />
   The brick plant has large manufacturing, storage and curing facilities in addition to its own transportation in order to meet client requirements on time. It is situated at Palani Road, Dindigul - 624 622, next to SSM Engineering College on Mylapore Road.<br />
   <br />
  <b>1.	High-Quality Raw Materials:</b> <br />
•	Sourcing high-quality raw materials, including fly ash and other components, ensures the production of durable and reliable bricks.<br />
<b>2.	Advanced Manufacturing Technology:</b><br />
•	Implementation of modern and efficient manufacturing equipment and processes can enhance production efficiency and product quality<br />
<b>3.	Compliance with Standards:</b><br />
•	Adherence to industry standards and regulations ensures that the produced bricks meet quality and safety requirements.<br />
<b>4.	Quality Control Measures:</b><br />
•	Robust quality control processes, including testing procedures, contribute to the production of high-quality and consistent bricks<br />
<b>5.	Strategic Location:</b><br />
•	Proximity to raw material sources, efficient transportation routes, and key markets can optimize logistics and reduce operational costs.<br />

</p>
    </div>
  )
}

export default about