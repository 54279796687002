import React from 'react'
import "./App.css";

function Footer() {
  return (
    
    <div className="footer"> 
      <div className="address">
    <p>
      <b>Address:</b>Sri Gowmari Modern Bricks,
    </p>
    <p> SF No 630/1, Mylapore Road, </p>
    <p>Near SSM Engg</p>
    <p>College, Palani Road, Dindigul - 624 622.</p>
    </div>
  <div className="email">
    <b>Email</b>sgmbricks2021@gmail.com
  </div>
  <div className="phone">
    <b>Phone</b>9500618429,9738734088
    </div>
  </div>
  )
}

export default Footer