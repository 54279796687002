import React from 'react'
import { BrowserRouter as Router, Route,Routes, Link } from 'react-router-dom';
import "./App.css";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Footer from "./Footer";

function Header() {
  return (
   
   <div className="header">
    <div className='top-row'>
    <div className="logo">
    <img src="http://sgmmodernbricks.com/img/Logo.png" alt="Logo"></img>
    </div>

    <div className="tag">
    <img src="http://sgmmodernbricks.com/img/tag.png" alt="Logo"></img>
    <h2> ISO 9001:2015 Certified</h2>
    <h2>Contact 9500618429,9738734088 for purchase of flyash bricks and solid blocks</h2>
   </div>

   
    </div>

    <div className="header__ribbon">
    <Link to="/">Home</Link>
    <Link to="/About">About Us</Link>
    <Link to="/Contact">Contact</Link>
    </div>
    <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
      </Routes>


  </div>

  
 
  
  )
}

export default Header