import React from 'react'
import "./App.css";

function Home() {
  return (
    <div><div className="imageContainer">
    <img src="http://sgmmodernbricks.com/img/front.jpg" alt="front"></img>
  </div>

  <div className="bottomImageContainer">
    <img src="http://sgmmodernbricks.com/img/fi1.jpg" alt="front"></img>
    <img src="http://sgmmodernbricks.com/img/fi2.jpg" alt="front"></img>
    <img src="http://sgmmodernbricks.com/img/fi3.jpg" alt="front"></img>
  </div></div>
  )
}

export default Home