import React from "react";
import { BrowserRouter as Router, Route,Routes, Link } from 'react-router-dom';
import "./App.css";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Header from "./Header";
import Footer from "./Footer";

function App() {
  return (
    <Router>
    <div className="container">
    <Header />
    <Home/> 
    <Footer />
  
    </div>
    </Router>
  );
}

export default App;